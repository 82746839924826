<template>
  <div class="password">
    <div class="template-page-top">
      <div class="title">账号设置</div>
    </div>
    <el-form
      class="animated fadeInUp"
      ref="form"
      :model="form"
      :rules="rules"
      hide-required-asterisk
    >
      <el-form-item
        label="手机号"
        v-model="userInfo.phone"
        :label-width="formLabelWidth"
        prop="phone"
      >
        <el-input
          v-model="userInfo.phone"
          disabled
          autocomplete="off"
          placeholder="请输入手机号"
          maxlength="20"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="短信验证码"
        :label-width="formLabelWidth"
        v-model="form.msgcode"
        prop="msgcode"
      >
        <div class="col">
          <el-input
            class="user-input"
            placeholder="请输入短信验证码"
            v-model.trim="form.msgcode"
            maxlength="6"
          >
          </el-input>
          <div class="append-div">
            <div
              class="code"
              @click="getCode"
              :class="codeBtnStyle ? 'active' : ''"
            >
              <span v-if="codeBtnStyle">{{ isCountdown }}</span
              >{{ codeTxt }}
            </div>
          </div>
        </div>
      </el-form-item>

      <el-form-item
        label="新密码"
        v-model="form.password"
        :label-width="formLabelWidth"
        prop="password"
      >
        <el-input
          v-model.trim="form.password"
          autocomplete="off"
          placeholder="请输入新密码"
          maxlength="18"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="dialog-footer">
      <el-button
        class="ok-button animated fadeInUp"
        type="primary"
        @click="editPassword('form')"
        >确 定</el-button
      >
    </div>
  </div>
</template>
<script>
import { postEditPasswordApi, postSendMessageApi } from "@/request/api";

import { mapState } from "vuex";

export default {
  data() {
    return {
      // 验证码
      code: "",
      codeTxt: "获取验证码",
      isCountdown: 60,
      codeBtnStyle: false,
      setT: null,
      form: {
        msgcode: "",
        password: "",
      },
      rules: {
        password: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            required: true,
            min: 6,
            max: 18,
            message: "长度在 6 - 18 个字符",
            trigger: "blur",
          },
        ],
        msgcode: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            required: true,
            len: 6,
            message: "长度在 6 个字符",
            trigger: "blur",
          },
        ],
      },
      formLabelWidth: "150px",
    };
  },
  watch: {
    // 监听倒计时
    isCountdown(newVal) {
      if (!newVal) {
        this.codeBtnStyle = false;
        clearInterval(this.setT);
        this.setT = null;
        this.codeTxt = "获取验证码";
        this.isCountdown = 60;
      }
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods: {
    // 修改密码
    async postEditPasswordApi() {
      const { code, msg } = await postEditPasswordApi(this.form);
      if (code === 0) {
        this.$notify({
          title: "提示",
          message: "已修改",
        });
      } else {
        this.$notify({
          title: "提示",
          message: msg,
        });
      }
    },
    // 提交
    editPassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.postEditPasswordApi();
        } else {
          return false;
        }
      });
    },
    // 获取验证码
    async getCode() {
      if (this.codeBtnStyle) return;
      // 获取短信验证码
      const { code } = await postSendMessageApi({
        phone: this.userInfo.phone,
        tid: 2,
      });
      if (code === 0) {
        this.codeBtnStyle = true;
        this.codeTxt = "秒后重新获取";
        this.setT = setInterval(() => {
          this.isCountdown--;
        }, 1000);
        this.$notify({
          title: "提示",
          message: "已发送",
        });
      }
    },
  },
};
</script>

<style lang="less" scoped>
.password {
  .template-page-top {
    width: 100%;
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 48px;
    margin-bottom: 22px;
    .title {
      width: 50%;
      line-height: 22px;
      height: 22px;
      font-size: 22px;
      font-weight: bold;
      padding-left: 11px;
      border-left: 4px solid #3e90e5;
      border-radius: 2px;
      color: #fff;
    }
  }
  /deep/.el-input__inner {
    background-color: #081c30;
    border: 1px solid #385576;
    color: #57708d;
    width: 282px;
  }

  /deep/.el-form-item__label {
    font-size: 14px;
    color: #fff;
    padding: 0 24px 0 0;
  }

  /deep/.dialog-footer {
    padding: 0 0 0 150px;

    .el-form-item__content {
      width: 353px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  .col {
    width: 100%;
    position: relative;
    .append-div {
      height: 100%;
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      .code {
        padding: 0 16px;
        font-size: 14px;
        color: #3e90e5;
        position: relative;
        cursor: pointer;
        &::before {
          content: "";
          width: 1px;
          height: 21px;
          background: #e8e8e8;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 0;
        }
        &.active {
          cursor: default;
          color: #999;
        }
      }
    }
    .user-input {
      .el-input__inner {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        &:focus {
          border-color: #3e90e5;
        }
        &::-webkit-input-placeholder {
          color: #999;
          font-size: 14px;
        }
      }
    }
  }
}
</style>
